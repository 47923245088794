.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный цвет */
    z-index: 1000; /* Выше других элементов */
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1001; /* Выше backdrop */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Тень */
  }
  
  .app {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  