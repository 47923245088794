@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@638&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@638&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@638&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Prosto+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Fonts */

.inter{
    font-family: "Inter", sans-serif;
}
.roboto {
    font-family: "Roboto", sans-serif;
}
.prosto-one {
  font-family: "Prosto One", sans-serif;
}
/* Fonts */

.Dropdown-control {
    cursor: pointer;
    font-style: normal!important;
    font-size: 16px!important;
    background-color: transparent!important;
    border: none!important;
    color: rgba(0, 0, 0, 0.70)!important; 
    text-transform: uppercase!important;
    padding-right:25px!important;
  }
  .Dropdown-menu {
    font-style: normal;
    font-size: 16px!important;
    max-height: 300px!important;
    
  }
  .Dropdown-arrow {
    border-color: rgba(0, 0, 0, 0.70) transparent transparent!important;
    margin-top: 3px;
   
  }
  .is-open .Dropdown-arrow {
    border-color: transparent transparent rgba(0, 0, 0, 0.70)!important;
  }

  .textcolor{
    color: rgba(0, 0, 0, 0.70); 
  }
  
  .button{
    display: inline-flex;
    padding: 14px 30px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px; 
  }
  .button2{
    display: inline-flex;
    padding: 8px 14px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px; 
  }